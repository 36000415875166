import { ActionTypes } from "../types";

export default function(state = { session: {}, logged: false }, action){
    switch(action.type) {
        case ActionTypes.USER_INITIALIZE_SESSION: 
            return {  session: {...action.payload.data}, logged: true };
        case ActionTypes.USER_DESTROY_SESSION: 
            return { session: {}, logged: false };
        default: return state;
    }
}