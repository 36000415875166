import React from "react";
import axios from "axios";
import { connect } from "react-redux";

import errorAxiosHandler from "../../handlers/errorAxiosHandler";
import { logIn } from "../../data/actions/UserActions";
import { tableChangeCurrentDate } from "../../data/actions/TableActions";
import { AppContext } from "../../providers";

import "./Authorization.scss";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

class Authorization extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            block: false
        }
    }

    componentDidMount(){
        this.root = document.querySelector(".root");
        this.root.classList.toggle("root_fullscreen"); 
    }

    componentWillUnmount(){
        this.root.classList.toggle("root_fullscreen");
    }

    checkResponseURL(url){
        let origin = window.location.origin.replace(/\//g, "\\/");
        let regexp = new RegExp(`^${origin}`);
        return regexp.test(url);
    }

    login = () => {
        this.setState({block: true});
        axios.post("/api/user/login", {
            login: this.loginInput.value,
            password: this.passwordPassword.value,
        }).then((response) => {
            console.log('Auth response:', response);
            if(response.data.status && this.checkResponseURL(response.request.responseURL)) {
                this.context.start(response.data.data);
            } else { 
                this.setState({block: false});
                this.context.showMessage(response.data.data, 'error');
            }
        }).catch(errorAxiosHandler.bind(this.context, () => {
            this.setState({block: false});
        }));
    }

    render(){
        return(
            <section className="page">
                <div className="authorization">
                    <div className="form">
                        {/* <div className="form-header"><span>Введите пароль</span></div> */}
                        <div className="form__body">
                            <span className="form__body-title">Bxoд</span>
                            <div className="form__body-row">
                                <input className="form__body-input" required type="text"  autoComplete="false" ref={(input) => { this.loginInput = input}} />
                                <label className="form__body-label"><span className="form__body-label-content">Логин</span></label>
                            </div>
                            <div className="form__body-row">
                                <input className="form__body-input" required type="password" ref={(input) => {this.passwordPassword = input}} />
                                <label className="form__body-label"><span className="form__body-label-content">Пароль</span></label>
                            </div>
                            <button disabled={this.state.block} className="button button__main" onClick={this.login}>Войти</button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
} 

Authorization.contextType = AppContext;

// const mapStateToProps = (state) => ({
//     logged: state.user.logged,
// })

export default compose( withRouter, connect(null, { logIn, tableChangeCurrentDate }))(Authorization)
