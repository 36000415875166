export default {
    isNumber(value){
        if(value === '') return false;
        return isNaN(Number(value)) ? false : true;
    },
    toComma(value){
        if(typeof value == 'string' && /(\d+\,\d{2,})/.test(value)) return value.match(/\d+\,\d{2}/)[0];
        else if(typeof value == 'string' && /(\d+\.\d{2,})/.test(value)) return value.match(/\d+\.\d{2}/)[0].replace('.', ',');
        else if(typeof value == 'string') {
            if(this.isNumber(value)) return value+',00';
            else throw new Error(`Can't convert current value: ${value}`);
        }
        return ~value.toFixed(2).indexOf('.') ? (value).toFixed(2).replace('.', ',') : (value.toString()+',00');
    },   
    toDot(value){
        if(/,/.test(value)) value = value.replace(',', '.');
        else if(this.isNumber(value)) ;
        else return 0;
        // if(typeof value == 'string' && /((?:-)?\d+\,\d{1,})/.test(value)) {
        //     console.log(value.match(/(?:-)?\d+\,\d{1,}/));
        //     return Number(value.match(/(?:-)?\d+\,\d{1,}/)[0].replace(',', '.'));
        // }
        // else if(this.isNumber(value)) { 
        //     console.log('gert');
        //     return Number(value);
        // }
        // else return 0;
        // console.log('return number',value, Number(value).toFixed(2), Number(value))
        // return Number(value).toFixed(2);
        // console.log(Number(Number(value).toFixed(2)))
        return Number(Number(value).toFixed(2));
    }
} 