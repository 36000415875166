import React from "react";
import { withRouter } from "react-router-dom";

import { AppContext} from "../../../../providers";

class BillsMenu extends React.Component {
    render(){
        return(
            <div className="situational-menu">
                <ul>
                    <li onClick={() => { 
                        this.context.save(this.props.location.pathname);
                    }}>Cохранить</li>
                    {/* <li onClick={() => { 
                        this.context.search();
                    }}>Поиск</li> */}
                    {/* <li>Очистить</li>
                    <li>Удалить</li> */}
                </ul>
            </div>
        );
    }
}

BillsMenu.contextType = AppContext;

export default withRouter(BillsMenu);