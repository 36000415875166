import Validator from "./Validator";
import numfns from "../utils/numfns";
import headers from "../components/Table/BillsTable/headers";

class BillsValidator extends Validator {
    constructor(){
        super(headers);
    }


    check(unsavedData){

        let parentCheck = super.check(unsavedData);
        for (let index = 0; index < unsavedData.length; index++) {
            const row = unsavedData[index];

            if(!numfns.isNumber(row.id)) continue;
            
            if(row.action) delete row.action;

        }

        return parentCheck;
    }

    checkRow(row){
        // console.log('Check row: ', row);
        // // if(row.params && !row.params.editable) return true;
        // // if(!this.checkKeysExist(headers, row)) return false;
        // if(!super.checkCells(headers, row)) return false;

        return true;
    }
}

export default BillsValidator;