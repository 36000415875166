import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {getSocket} from "../../../socket/socket";
import numfns from "../../../utils/numfns";

import './BankInfo.scss';
import { AppContext } from '../../../providers';
import errorAxiosHandler from '../../../handlers/errorAxiosHandler';

class BankInfo extends React.Component {
    
    constructor(props){
        super(props);

        this.socket = getSocket();
        // this.request = axios.CancelToken.source();
        this.state = {
            balance: 0
        };
    }

    componentDidMount(){
        // const socket = socketClient('localhost:7500');
        // console.log('ws first use');
        this.socket.on("balance", data => {
            this.setState({balance: data});
        });

        axios.get('/api/bank').then(({data: response}) => {
            if(response.status) this.setState({balance: response.data[0].balance})
        }).catch(errorAxiosHandler.bind(this));

    }

    componentWillUnmount(){
        //console.log('UserInfo componentWillUnmount');
        // this.request.cancel();
    }

    render(){
        return (
            <div className="bank-info">
                {/* <div className="user-menu">
                    <span>Вы вошли как:</span>
                    <span>{this.props.session['first_name']} {this.props.session['second_name']}</span>
                </div> */}
                <div className="bank-info__content">
                    <span className="bank-info__content-row-title">На счете:</span>
                    <span className="bank-info__content-row">{numfns.toComma(this.state.balance)}</span>
                </div>
                {/* <div className="app-menu">
                    <div className="sub-menu">
                    </div>
                    <div className="app-menu-item"><MailIcon/></div>
                    <div className="app-menu-item"><MenuIcon/></div>
                </div> */}
            </div>
        );
    }
}

BankInfo.contextType = AppContext;

const mapStateToProps = (state) => ({
    session: state.user.session
})

export default connect(mapStateToProps)(BankInfo);