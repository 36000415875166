import React from "react";

import CalendarMenu from "./menus/CalendarMenu";
import PaymentsMenu from "./menus/PaymentsMenu";
import BillsMenu from "./menus/BillsMenu";
import SearchMenu from "./menus/SearchMenu";

export default function(path){
    switch(path){
        case '/bills':
            return <BillsMenu/>;
        case '/payments':
            return <PaymentsMenu/>;
        case '/calendar':
            return <CalendarMenu/>;
        case '/search':
            return <SearchMenu/>;
        default: return <div className="situational-menu"></div>;
    }
}