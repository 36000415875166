import React from "react";
import { connect } from "react-redux"

import Substrate from "../Substrate/Substrate";
import { pageConfirmTrigger } from "../../data/actions/AppActions";

import './Confirm.scss';


function event(){
    let events = [];
    return {
        trigger: function(name, ...args){
            let event = events.find((event) => event.name = name);
            if(event) event.callback.apply(null, args);
        },
        on: function(name, callback){
            let index = events.findIndex(event => event.name = name);
            if(~index) events[index] = {name, callback};
            else events.push({name, callback})
        },
    }
}

let confirmMessage = event();

class Confirm extends React.Component {
    render(){
        return (
            this.props.showPrompt || this.props.confirm ? 
            <Substrate>
            <div className="promt">
                <div className="promt__text-wrapper">
                    <div className="promt__text-wrapper-content">{this.props.message || this.props.customMessage}</div>
                </div>
                <div className="promt__controls">
                    <button className="button button__main" onClick={e => { 

                        if(this.props.showPrompt) this.props.callback(true);
                        else confirmMessage.trigger('answer', true);

                    }}>Покинуть</button>
                    <button className="button button__secondary" onClick={e => { 

                        if(this.props.showPrompt) this.props.callback(false);
                        else confirmMessage.trigger('answer', false);

                    }}>Остаться</button>
                </div>
            </div> 
            </Substrate>: null
        );
    }
}

const mapStateToProps = (state) => ({
    confirm: state.global.confirm,
    customMessage: state.global.message,
});

const mapDispatchToProps = {
    pageConfirmTrigger
}

export { confirmMessage };
export default connect(mapStateToProps, mapDispatchToProps)(Confirm);