import React from "react";
import axios from "axios";
import uuid from "uuid/v1";
import { compose } from "redux";
import { format } from "date-fns";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import errorAxiosHandler from "../handlers/errorAxiosHandler";
import { logOut, logIn } from "../data/actions/UserActions";
import { tableSaved, tableChangeData, tableChangeStatus, 
        tableClearChanges, tableDataAdjustIds,
        tableDataDeleteRow, tableUnsavedDataDeleteRow, tableChangeCurrentDate } from "../data/actions/TableActions";
import { pageLoadStatus, pagePopupAdd, pageSearchTrigger } from "../data/actions/AppActions";

import { initSocket, getSocket } from "../socket/socket";
import Timer from "../utils/timer";
import validators from "../validators";

const AppContext = React.createContext();
const sessionTimer = new Timer();

class AppProvider extends React.Component {


    shouldComponentUpdate(nextProps, nextState){
        return nextProps != this.props ? true : false;
    }

    constructor(props){
        super(props);

        // this.sessionCheck = false;

        this.state = {
            funcs: {
                initTimer: (() => {
                    // console.log('init')
                    this.props.tableChangeData([]);
                    this.props.location.pathname = '/';
                    axios.get('/api/app/session').then(({data: response}) => {
                        if(!response.status) this.props.logOut();
                        else { 
                            
                            this.start(response.data);
                        }
                        // this.sessionCheck = true;
                        document.body.removeChild(document.getElementById('loader'))
                    }).catch(errorAxiosHandler.bind(this, () => {
                        document.body.removeChild(document.getElementById('loader'));
                    }));
                    // } else {
                        // document.body.removeChild(document.getElementById('loader'))
                    // }
                })(),
                start: (data) => this.start(data),
                stop: () => this.logout(),
                search: () => {
                    this.props.pageSearchTrigger(true);
                },
                save: (path) => {
                    this.props.pageLoadStatus(false);
                    let data = [...this.props.unsavedData];

                    if(typeof data[0] == 'string') data.shift();

                    let validator = validators(path);
                    let status = validator.check(data);
                    // console.log('try to save: ', status);
                    if(status) {
                        data.unshift(this.props.date);

                        this.props.tableSaved(data, path).then(response => {
                            //console.log('AFTER SAVE:', response);
                            if(response.status) {
                                data.shift();
                                this.props.tableDataAdjustIds(data, response.data);

                                if(data.length === response.data.length) {
                                    this.props.tableClearChanges();
                                    this.props.tableChangeStatus(false);
                                }

                                this.showMessage('Успешно сохранено.', 'success');
                            }
                            else this.showMessage('При сохранении произошла ошибка. Попробуйте позже. \n Данные не сохранены', 'error');
                            this.props.pageLoadStatus(true);
                        });
                    } else { 
                        // console.log('Noting change');
                        this.showMessage('Сохранение невозможно. Проверьте данные.', 'error');
                        this.props.pageLoadStatus(true); 
                    }
                },
                delete: (id) => {
                    if(Number(id)){ 
                        axios.delete(`/api/table/bills/${id}`).then(({data: response}) => {
                            if(response.status) {
                                this.props.tableDataDeleteRow(id);
                                this.props.tableUnsavedDataDeleteRow(id);
                                this.showMessage('Запись успешно удалена', 'success');
                            } else {
                                this.showMessage('Удаление не удалось', 'error');
                            }
                        }).catch(errorAxiosHandler.bind(this));
                    } else {
                        this.props.tableDataDeleteRow(id);
                        this.props.tableUnsavedDataDeleteRow(id);
                    }
                },
                showMessage: (text, type) => this.showMessage(text, type),
                discardChanges: () => {
                    this.props.tableClearChanges();
                    this.props.tableChangeStatus(false);
                }
                // tryRestore: () => this.tryRestore(),
            }
        }
    }

    logout(){
        // console.log("User out");
        axios.get('/api/user/logout').then(({data: response}) => {
            if(response.status) {
                getSocket().disconnect();
                this.kickUser();
            }
        }).catch(errorAxiosHandler.bind(this));
    }

    kickUser(){
        sessionTimer.stop();
        this.props.logOut();
    }

    showMessage(text, type) {
        this.props.pagePopupAdd({ text, type, key: uuid(), expire: Date.now()+3500 });
    }


    tryRestore(data){
        // console.log('TRY RESTORE', this.props.unsavedData);
        if(this.props.unsavedData.length){
            this.props.tableChangeStatus(true);
            this.props.tableChangeCurrentDate(this.props.upd_date);
            this.props.logIn(data);
            this.props.history.push(`/${this.props.tableName}`);
        } else {
            this.props.tableChangeCurrentDate(format(new Date(), 'dd.MM.yyyy'));
            this.props.logIn(data);
            this.props.history.push("/calendar");
        }

    }
    start = (data) => {
        // console.log('try setup socket');
        initSocket();

        if(!sessionTimer.isActive){
            sessionTimer.time = 15000;
            sessionTimer.start();
            sessionTimer.sync = setInterval(() => { 
                axios.get('/api/app/session').then(({data: response}) => {

                    let isExist = response.status;
                    //console.log('isExist: ', isExist, this.props.logged);

                    if(!isExist && sessionTimer.isActive){
                        this.logout();
                    }
                }).catch(errorAxiosHandler.bind(this, () => {
                    this.kickUser();
                }));
            }, sessionTimer.time);
        }

        this.tryRestore(data);
    }

    shouldComponentUpdate(newProps, newState){
        // console.log(pr, st, this.props, pr.unsavedData == this.props.unsavedData);
        return true;
    }

    componentWillUnmount(){
        //console.log('provider unmount')
    }
    render(){
        // console.log('PROVIDER RENDER', this.props);
        return <AppContext.Provider value={this.state.funcs} {...this.props}/>
    }
}

const mapStateToProps = (state) => ({
    logged: state.user.logged,
    user: state.user.session,
    unsavedData: state.app.table.unsavedData,
    tableName: state.app.table.name,
    upd_date: state.app.table.upd_date,
    date: state.table.date,
});

const mapDispatchToProps = {
    logIn,
    logOut,
    tableSaved,
    tableClearChanges,
    tableChangeStatus,
    tableDataAdjustIds,
    tableChangeData,
    tableDataDeleteRow,
    tableChangeCurrentDate,
    tableUnsavedDataDeleteRow,
    pageLoadStatus,
    pagePopupAdd,
    pageSearchTrigger,
}

export { AppContext };
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppProvider);