import "./App.scss";

import React, { Suspense } from "react";
import { BrowserRouter as Router, Prompt, withRouter, Switch, Route, Redirect  } from "react-router-dom"; 

import { connect} from "react-redux";

import Loader from "./components/Loader";
import ErrorBoundary from "./components/ErrorBoundary"
import Header from "./components/Header";
// import Page from "./components/Page";
import Authorization from "./components/Authorization";
import PopupManager from "./components/PopupManager/PopupManager";
import Confirm from "./components/Confirm/Confirm";
import Substrate from "./components/Substrate/Substrate";
import ContextMenu from "./components/ContextMenu/ContextMenu"

import AppProvider from './providers';

import { tableClearChanges, tableChangeStatus } from "./data/actions/TableActions";


const Page = React.lazy(() => import('./components/Page'));

class App extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      showPrompt: false,
      callback: () => {},
      message: null,
    }

  }

  componentDidMount(){

  }

  customGetUserConfirmation(message, navCallback){
    this.setState({
      showPrompt: true,
      message,
      callback: (allow) => {
        navCallback(allow);
        this.setState({showPrompt: false});
        if(allow){
          this.props.tableClearChanges();
          this.props.tableChangeStatus(false);
        }
      }
    });
  }

  render() {
    return (
      <Router getUserConfirmation={this.customGetUserConfirmation.bind(this)}>
        <AppProvider>
          {
            this.props.logged ?  
            <>
              <Confirm {...this.state}/>
              <Header/>
              <ErrorBoundary>
                <Suspense fallback={<Loader/>}>
                  <Page/>
                </Suspense>
              </ErrorBoundary>
            </> 
            : <Authorization/>

          }
          <ContextMenu />
        </AppProvider>
        <PopupManager/>
      </Router>
    );
  }
}


const mapStateToProps = (state) => ({
  logged: state.user.logged,
});

export default connect(mapStateToProps, { tableClearChanges, tableChangeStatus })(App)