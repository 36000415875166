import React from 'react';
import './PopupMessage.scss';

class PopupMessage extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        return (nextProps.text != this.props.text && nextProps.type != this.props.type)
    }

    // componentDidMount(){
    //     console.log('MESSAGE MOUNT');
    // }


    render(){
        return <div onClick={e => this.props.close(this.props.objectMessage)} className={`popup ${this.props.type}`}>
            {this.props.text}
        </div>
    }
}

export default PopupMessage;