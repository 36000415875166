import React from "react";
import { connect } from "react-redux";
import './Substrate.scss';

class Substrate extends React.Component {
    render(){
        // return this.props.show || this.props.showConfirm ? <div className="substrate show"></div> : null;
    return <div className="substrate">{this.props.children}</div>;
    }
}

const mapStateToProps = (state) => ({
    showConfirm: state.global.confirm
});

export default connect(mapStateToProps)(Substrate);