const contextModel = {
    table: {
        's': {
            title: 'Сохранить',
            command: 's_t',
        }   
    },
    row: {
        'd': {
            title: 'Удалить',
            command: 'd_r',
        },
        'dar': {
            title: 'Удалить и пересчитать баланс',
            command: 'dar_r',
        },
        'sbdi': {
            title: 'Искать по дате добавления',
            command: 'sbdi_r'
        },
        'dnr': {
            title: 'Удалить дальнейшие записи',
            command: 'dnr_r'
        }
    },
    cell: {
        'sbd': {
            title: 'Искать по дате'
        },
        'sbc': {
            title: 'Искать по контрагенту'
        }
    }

}

export default contextModel;