import React from "react";
import { connect } from "react-redux";

import { pageLoadStatus } from "../../data/actions/AppActions";
import { AppContext } from "../../providers";


class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        }
    }

    // static getDerivedStateFromError(error) {
    //     console.log('error 2');
    //     // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    //     // return { hasError: true };
    //   }

    componentDidCatch(error, errorInfo){
        this.props.pageLoadStatus(true);
        //console.log(Object.keys(errorInfo));
        this.setState({
            error,
            errorInfo
        });
    }

    render(){

        if(this.state.errorInfo){
            this.context.showMessage(`Произошла ошибка. ${this.state.error.toString()}`, 'error')
            return null;
        } 
        return this.props.children;
    }

}

ErrorBoundary.contextType = AppContext;

export default connect(null, {pageLoadStatus})(ErrorBoundary);