import React from 'react';
import uuid from 'uuid/v1';
import { connect } from "react-redux";
import PopupMessage from "./PopupMessage/PopupMessage";

import { pagePopupAdd, pagePopupRemove } from "../../data/actions/AppActions";
import './PopupManager.scss';

class PopupManager extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            messages: [
                { text: 'Данные успешно сохранены в базе данных', type: 'success', key: uuid(), expire: Date.now()+3000 },
                { text: 'Сохранение не удалось', type: 'warning', key: uuid(), expire: Date.now()+3000 },
                { text: 'Сохранение не удалось', type: 'error', key: uuid(), expire: Date.now()+3000 },
            ]
        }
    }

    componentDidMount(){
        this.manageMessages();
    }

    manageMessages(){
        setInterval(() => {
            if(this.props.messages.length){
                this.props.messages.forEach(message => {
                    // console.log('checking', message.expire <= Date.now())
                    if(message.expire <= Date.now()) { 
                        this.props.pagePopupRemove(message);
                    }
                });
            }
        }, 500);
    }

    render(){ 
        // console.log('popup manager render')
        return (
            <>
                { this.props.messages.length ? 
                    <div className="popup-message-area">
                        { this.props.messages.map(message => 
                            <PopupMessage key={message.key} 
                                text={message.text} 
                                type={message.type}
                                objectMessage={message}
                                close={this.props.pagePopupRemove}/>) 
                        }
                    </div> : null }
                {/* <button onClick={(e) => {
                    this.props.pagePopupAdd({ text: 'Данные успешно сохранены в базе данных', type: 'success', key: uuid(), expire: Date.now()+3000 });
                }}>Add success</button>
                <button onClick={(e) => {
                    this.props.pagePopupAdd({ text: 'Данные успешно', type: 'warning', key: uuid(), expire: Date.now()+3000 });
                }}>Add warning</button>
                <button onClick={(e) => {
                    this.props.pagePopupAdd({ text: 'Данные успешно сохранены в базе данных', type: 'error', key: uuid(), expire: Date.now()+3000 });
                }}>Add error</button> */}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    messages: state.global.popupMessages
});

const mapDispatchToProps = { pagePopupAdd, pagePopupRemove }

export default connect(mapStateToProps, mapDispatchToProps)(PopupManager);