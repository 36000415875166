import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";

import appReducer from "./reducers/AppReducer";
import persistTableReducer from "./reducers/PersistTableReducer";
import tableReducer from "./reducers/TableReducer";
import userReducer from "./reducers/UserReducer";
import registerReducer from "./reducers/RegisterReducer";

const persistConfig = {
    key: "root",
    storage
}

let persistedReducer = combineReducers({
    table: persistTableReducer,
})


let reducer = combineReducers({
    app: persistReducer(persistConfig, persistedReducer),
    register: registerReducer,
    global: appReducer,
    user: userReducer,
    table: tableReducer,
});

// const persistedReducer = persistReducer(persistConfig, reducer);

export default function(initialState) {
    const store = createStore(reducer, initialState, applyMiddleware(thunkMiddleware));
    // console.log(store.getState());
    const persistor = persistStore(store);
    return { store, persistor }
}