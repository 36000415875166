import uuid from "uuid/v1";

let headers = [
    { title: 'ID',              style: { width: "30px"},    name: 'id',             type: 'id',         editable: false },
    { title: 'Контрагент',      style: { width: "280px"},   name: 'counterparty',   type: 'string', context: "cell-sbc"},
    { title: 'Назначение',      style: { width: "215px"},   name: 'purpose',        type: 'string'},
    { title: 'Объект',          style: { width: "215px"},   name: 'object',         type: 'string'},
    { title: 'Действие',        style: { width: "65px"},    name: 'action',         type: 'boolean',    editable: false },
    { title: 'Дата',            style: { width: "142px"},    name: 'date',           type: 'date', context: "cell-sbd" },
    { title: 'Номер счета',     style: { width: "215px"},   name: 'bill',           type: 'string'},
    { title: 'Сумма',           style: { width: "115px"},   name: 'total',          type: 'double' },
    { title: 'Срок',            style: { width: "40px"},    name: 'term',           type: 'int' },
    { title: 'Конечный срок',   style: { width: "79px"},   name: 'end_date',       type: 'date',       editable: false },
    { title: 'Долг',            style: { width: "115px"},   name: 'debt',           type: 'double',     editable: false }
];

headers = headers.map(header => ({ ...header, key: uuid()}));

export default headers;