export default class CommandManager {

    constructor(commands, context) {
        this.commands = commands;
        this.context = context;
    }

    exec(name, params = []){
        let command = this.commands.find((command) => command.name === name);
        if(command) command.method.call(this.context, ...params);
    }

}
