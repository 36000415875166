import { ActionTypes } from "../types";

export default function(state = { displayed: false, x: 0, y: 0, data: [] }, action){
    switch(action.type){
        case ActionTypes.REGISTER_DATA_CHANGE: 
            return {...state, data: action.payload.data}
        case ActionTypes.REGISTER_DISPLAY_CHANGE:
            return {...state, ...action.payload.data}
        case ActionTypes.REGISTER_HIDE: 
            return {...state, displayed: false}
        default: return state;
    }
}