import { ActionTypes } from "../types";

const initialState = {
    unsavedData: [], 
    upd_date: null,
    name: null,
}


export default function(state = { ...initialState }, action){
    let unsavedData = state.unsavedData.slice(), index;
    // console.log('UNSAVED DATE: ', state.unsavedData, action)

    switch(action.type){
        case ActionTypes.TABLE_CHANGE_UPD_DATE: return { ...state, upd_date: action.payload.data }
        case ActionTypes.TABLE_CHANGE_UPD_TABLE_NAME: return { ...state, name: action.payload.data}
        case ActionTypes.TABLE_UNSAVED_DATA_ADD_ROW: 
            index = unsavedData.findIndex((element) => element.id == action.payload.id);

            if(~index){
                unsavedData[index] = {...unsavedData[index], ...action.payload.content}
            } else {
                unsavedData.push({id: action.payload.id, ...action.payload.content})
            }

            return { ...state, unsavedData};
        case ActionTypes.TABLE_UNSAVED_DATA_CHANGES_ROW_ID: 
            index = unsavedData.findIndex((element) => element.id == action.payload.oldId);

            if(~index){
                unsavedData[index] = {...unsavedData[index], id: action.payload.newId}
            } 

            return { ...state, unsavedData};
        case ActionTypes.TABLE_UNSAVED_DATA_DELETE_ROW:
            let afterUnsavedDeletion;

            if(~state.unsavedData.findIndex(row => row.id == action.payload.data)) {
                let filtered = state.unsavedData.filter(row => row.id != action.payload.data);
                afterUnsavedDeletion = { ...state, unsavedData: [...filtered] }
            }
            return afterUnsavedDeletion ? afterUnsavedDeletion : {...state};
        case ActionTypes.TABLE_CLEAR_CHANGES_DATA: 
            return {...state, unsavedData: []};
        default:  { 
            return  state;
        }
    }
}