import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom"; 
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"

//TODO: test provider without jsx [delete]
import { Provider as Session } from "./session/Provider";

// TODO: Think about this
import { tableChangeStatus, tableClearChanges } from "./data/actions/TableActions";


import createStore from './data/store';

import App from './App';

import "./index.scss";

const { store, persistor } = createStore();



let application = (
    <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </ReduxProvider>

);

ReactDOM.render(application, document.querySelector('.root'));

