import { ActionTypes } from "../types";

const initialState = {
    loaded: false, 
    title: '', 
    popupMessages: [],
    menu: false,
    confirm: false,
    search: true,
}

export default function(state = { ...initialState }, action) {
    switch(action.type){
        case ActionTypes.PAGE_LOAD_STATUS: 
            return {...state, loaded: action.payload.data};
        case ActionTypes.PAGE_TITLE_CHANGED: 
            return {...state, title: action.payload.data};
        case ActionTypes.PAGE_POPUP_ADD: 
            return { ...state, popupMessages: [...state.popupMessages, action.payload.data] }
        case ActionTypes.PAGE_POPUP_REMOVE:
            return { ...state, popupMessages: [...state.popupMessages.filter(message => message != action.payload.data)]}
        case ActionTypes.PAGE_MAINMAIN_SHOW: 
            return {...state, menu: true};
        case ActionTypes.PAGE_MAINMAIN_HIDE: 
            return {...state, menu: false};
        case ActionTypes.PAGE_MAINMAIN_TOGGLE: 
            return {...state, menu: !state.menu};
        case ActionTypes.PAGE_CONFIRM_TRIGGER: 
            return { ...state, confirm: action.payload.show, message: action.payload.message }
        case ActionTypes.PAGE_SEARCH_TRIGGER: 
            return { ...state, search: action.payload.show }
        default: return state;
    }
}