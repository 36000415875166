import React from "react";

class CalendarMenu extends React.Component {
    render(){
        return(
            <div className="situational-menu">
                <ul>
                    {/* <li>Поиск</li> */}
                </ul>
            </div>
        );
    }
}

export default CalendarMenu;