import socketClient from "socket.io-client";

let bksocket;
const local = false;

function initSocket(){
    bksocket = socketClient(`${local ? 'localhost:7500' : 'https://bkow.xyz'}`);
}

function getSocket(){
    return bksocket;
}

export { initSocket, getSocket}