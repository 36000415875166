import React from "react";

import generate from "./generateMenu";

import './SituationalMenu.scss';


class SituationalMenu extends React.Component {


    // shouldComponentUpdate(props, state){
    //     console.log(props, this.props, props == this.props);
    //     return true;
    // }

    render(){
        // console.log('RENDER SITMENU: ', this.props.pathname);
        return generate(this.props.pathname)
    }
}



export default SituationalMenu;