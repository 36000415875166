import React from "react";
import { connect } from "react-redux";

import "./Loader.scss";

import { ReactComponent as LoadingIcon } from "./assets/loading.svg";

class Loader extends React.Component { 

    render(){
        return (  
                <div className={`loader ${!this.props.loaded ? 'show' : 'hide' }`}>
                    {this.props.loaded ? null : <div className="loader-content">
                        <LoadingIcon/>
                    </div>}
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loaded: state.global.loaded
});

export default connect(mapStateToProps)(Loader);