import axios from "axios";

import Command from "./Command";
import utils from "../../utils/domfns";
import numfns from "../../utils/numfns";
import errorAxiosHandler from "../../handlers/errorAxiosHandler";

const commands = [
    new Command('s_t', function(){
        this.context.save(this.props.location.pathname);
    }),
    new Command('d_r', function(e){
        let idRow = utils.getRowId(this.contextMenuTargetElement)
        // let tr = utils.findTr(this.contextMenuTargetElement);

        // if(tr && tr.hasAttribute('row')) idRow = tr.getAttribute('row');

        if(idRow) this.context.delete(idRow);
    }),
    new Command('dar_r', function(e) {
        let idRow = utils.getRowId(this.contextMenuTargetElement);
        if(numfns.isNumber(idRow)) {
            axios.delete(`/api/table/bills/recalc/${idRow}`).then(({data: response}) => {
                if(response.status){
                    //console.log('Очищаем: ', idRow);
                    this.props.tableDataDeleteRow(idRow);
                   // console.log('Очищаем 2: ', idRow, this);
                    this.context.showMessage('Запись успешно удалена. Баланс обновлен.', 'success');
                    //console.log('Очищаем 3: ', idRow);
                } else {
                    this.context.showMessage(response.data, 'error');
                }
            }).catch(errorAxiosHandler.bind(this));
        } else {
            this.context.showMessage('Запись не сохранена. Невозможно пересчитать таблицу.', 'error');
        }
        
    }),
    ///////// PAYMENTS TABLE COMMANDS
    new Command('sbdi_r', function(e){
        let idRow = utils.getRowId(this.contextMenuTargetElement);
        if(idRow){ // TODO: CHECK idRow FOR NEW RECORDS
            axios.get(`/api/table/payments/date/${idRow}`).then(({data: response}) => {
                if(response.status) {
                    let date = new Date(response.data[0].date);
                    let validDate = date.toLocaleString('ru', {year: 'numeric', month: '2-digit',  day: '2-digit'});
                    // console.log('SERVER GIVE ME THAT: ', validDate, this);
                    this.props.tableChangeCurrentDate(validDate);
                    this.props.history.push('/bills');
                } else {
                    this.context.showMessage(response.data, 'error');
                }
               
            }).catch(errorAxiosHandler.bind(this));
        }
    }),
    new Command('dnr_r', function(e){
        let id = this.contextMenuTargetElement.parentElement.getAttribute('row');
        axios.delete(`/api/table/payments/delete/${id}/${this.props.date}`).then(({data: response}) => {
            if(response.status) {
                this.context.showMessage('Записи удалены.', 'success');
            } else {
                this.context.showMessage('Удаление невозможно.', 'error');
            }
        }).catch(errorAxiosHandler.bind(this));
        // console.log('Удалить записи!', this, this.contextMenuTargetElement.parentElement.getAttribute('row'));
    }),
];

export default commands;