export const ActionTypes = {
    PAGE_TITLE_CHANGED: 'page_title_changed',
    PAGE_LOAD_STATUS: 'page_load_status',
    PAGE_POPUP_ADD: 'page_popup_add',
    PAGE_POPUP_REMOVE: 'page_popup_remove',
    PAGE_MAINMAIN_SHOW: 'page_mainmenu_show',
    PAGE_MAINMAIN_HIDE: 'page_mainmenu_hide',
    PAGE_MAINMAIN_TOGGLE: 'page_mainmenu_toggle',
    PAGE_CONFIRM_TRIGGER: 'page_confirm_trigger',
    PAGE_SEARCH_TRIGGER: 'page_search_trigger',

    TABLE_CHANGE_CURRENT_DATA: 'table_change_current_data',
    TABLE_DATA_DELETE_ROW: 'table_data_delete_row',
    TABLE_DATA_ADD_NEW_ROW: 'table_data_add_new_row',
    TABLE_DATA_ADJUST_IDS: 'table_data_adjust_ids',
    TABLE_CURRENT_DATE_CHANGING: 'table_current_date_changing',
    TABLE_DATA_CHANGED: 'table_data_changed',
    TABLE_UNSAVED_DATA_CHANGED: 'table_unsaved_data_changed',
    TABLE_UNSAVED_DATA_ADD_ROW: 'table_unsaved_data_add_row',
    TABLE_UNSAVED_DATA_DELETE_ROW: 'table_unsaved_data_delete_row',
    TABLE_UNSAVED_DATA_CHANGES_ROW_ID: 'table_unsave_data_changes_row_id',
    TABLE_CLEAR_CHANGES_DATA: 'table_clear_changes_data',
    TABLE_CHANGE_UPD_DATE: 'table_change_upd_date',
    TABLE_CHANGE_UPD_TABLE_NAME: 'table_change_upd_table_name',

    USER_INITIALIZE_SESSION: 'user_initialize_session',
    USER_DESTROY_SESSION: 'user_destroy_session',

    REGISTER_DATA_CHANGE: 'register_data_change',
    REGISTER_DISPLAY_CHANGE: 'register_display_change',
    REGISTER_HIDE: 'register_hide',
}