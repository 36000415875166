import Validator from "./Validator";
import numfns from "../utils/numfns";
import { isValid, parse } from "date-fns";
import headers from "../components/Table/PaymentsTable/headers";

class PaymentsValidator extends Validator {
    constructor(){
        super(headers);
    }

    checkRow(row){
        //console.log('Check row: ', row);
        if(row.params && !row.params.editable) return true;

        if(!super.checkKeysExist(row)) return false;
        if(!super.checkCells(row)) return false;
        return true;
    }

}

export default PaymentsValidator;