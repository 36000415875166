import BillsValidator from './BillsValidator';
import PaymentsValidator from './PaymentsValidator';

const billsValidator  = new BillsValidator();
const paymentsValidator = new PaymentsValidator();

export default function(table){
    switch(table){
        case '/bills': return billsValidator;
        case '/payments': return paymentsValidator;
        default: return false;
    }
}