import React from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../../providers";

class PaymentsMenu extends React.Component {

    // shouldComponentUpdate(props, state){
    //     console.log(props, this.props, props == this.props);
    //     return true;
    // }

    // componentDidMount(){
    //     this.context.showMessage('Данные успешно сохранены в базе данных', 'success')
    // }

    render(){
        // console.log('RENDER SITUATIONAL MENU');
        return(
            <div className="situational-menu">
                <ul>
                    <li onClick={() => {
                        this.context.save(this.props.location.pathname);
                    }}>Cохранить</li>
                    {/* <li onClick={() => { 
                        this.context.search();
                    }}>Поиск</li> */}
                    {/* <li>Очистить</li> */}
                </ul>
            </div>
        );
    }
}

PaymentsMenu.contextType = AppContext;

export default withRouter(PaymentsMenu);