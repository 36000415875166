import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux"; 
import { connect } from "react-redux";


import commands from "./commands";
import utils from "../../utils/domfns";
import contextModel from "./ContextModel";
import CommandManager from "./CommandManager";
import { AppContext } from "../../providers";
import { tableChangeCurrentDate, tableDataDeleteRow } from "../../data/actions/TableActions";

import './ContextMenu.scss';

class ContextMenu extends React.Component {

    constructor(props){
        super(props);

        this.commandsMenager = new CommandManager(commands, this);

        this.state = {
            show: false,
            x: 0,
            y: 0,
            items: [],
        }

        this.contextMenuTargetElement = null;
        
        this.handleContextMenuClick = this.handleContextMenuClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(){
    }

    // shouldComponentUpdate(props, state){
    //     console.log(props, this.props, this.props == props, state, this.state, state == this.state);
    //     return true;
    // }

    hide(){
        if(this.state.show) {
            let tr = utils.findTr(this.contextMenuTargetElement);
            Promise.resolve(tr ? tr.classList.remove('active'): tr);
            this.setState({show: false});
        } 
    }

    // TODO: rename
    traversObject(obj, keys){
        let element = obj;
        keys.forEach(key => {
            if(element[key]) element = element[key];
        });

        return element;
    }

    handleScroll(e){
        this.hide();
    }

    handleClick(e){
        this.hide();
    }

    handleContextMenuClick(e){
        this.contextMenuTargetElement = e.target;
        let contextMenuHeight = 30;

        let element = e.target;
        let contextContent = '';

        while(element.parentElement || element.hasAttribute('context')){
            if(element.hasAttribute('context')) contextContent += element.getAttribute('context') + ' ';
            element = element.parentElement;
        }
        let menuParts = contextContent.trim().split(' ').reverse();
        let items = [];

        menuParts.forEach(element => {
            let menu = element.split('-');
            while(menu.length >= 2){
                items.push(this.traversObject(contextModel, menu));
                contextMenuHeight += 30;
                menu.splice(1,1);
            }
            items.push({hr: true});
            contextMenuHeight += 7;
        });
        items.pop();
        contextMenuHeight -= 7;
        // console.log(items.length, contextMenuHeight);
        this.setState({ 
            show: true,
            x: document.documentElement.clientWidth - e.pageX >= 300 ? e.pageX : document.documentElement.clientWidth - 300,
            y: ((document.documentElement.clientHeight-70) - (e.pageY-70)) >= contextMenuHeight ? e.pageY-70 : (document.documentElement.clientHeight-70) - contextMenuHeight+5, 
            items,
        });

        e.preventDefault(); 
    }

    handleContextMenuItemClick =(e) => { 
        e.persist();
        if(e.target.hasAttribute('command')) this.commandsMenager.exec(e.target.getAttribute('command'), [e]);
    }

    componentDidMount(){
        // document.querySelector('.table').addEventListener('scroll', (e) => console.log(2));
        document.addEventListener('contextmenu', this.handleContextMenuClick);
        window.addEventListener('click', this.handleClick);
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount(){
        document.removeEventListener('contextmenu', this.handleContextMenuClick);
        window.removeEventListener('click', this.handleClick);
        window.addEventListener('scroll', this.handleScroll, true);
    }

    render(){
        return (
            this.state.show && this.state.items.length ? <div className="context-menu" style={{left: this.state.x, top: this.state.y}}>
                <ul className="context-menu__items" onClick={this.handleContextMenuItemClick}>
                    { this.state.items.map(item => item.hr ? 
                        <div className="context-menu__blank" key={Math.random()}></div> : 
                        <li className="context-menu__item" key={Math.random()} command={item.command}>{item.title}</li>)
                    }
                </ul>
            </div> : null
        );
    }
}

ContextMenu.contextType = AppContext;

const mapStateToProps = (state) => ({
    date: state.table.date,
});

const mapDispatchToProps = { tableChangeCurrentDate, tableDataDeleteRow };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContextMenu);