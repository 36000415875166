import { ActionTypes } from "../types";

const initialState = {
    changed: false,  /////////////////
    date: null, //////////
    data: [], //////////////////
}

export default function(state = { ...initialState }, action){
    let unsavedData = [].slice(), index;

    switch(action.type){
        case ActionTypes.TABLE_CHANGE_CURRENT_DATA: 
        // console.log('check:', state.data == action.payload.data)
        return { ...state, data: action.payload.data } ///////////
        case ActionTypes.TABLE_DATA_ADD_NEW_ROW: return { ...state, data: [...state.data, action.payload.data ]} ////////////
        case ActionTypes.TABLE_DATA_DELETE_ROW:  /////////////////////////////
            let afterDeletion;
            // if(state.data.length > 1) {
                let filtered = state.data.filter(row => row.id != action.payload.data);
                afterDeletion = { ...state, data: [...filtered] }
            // }
            return afterDeletion ? afterDeletion : {...state};
        case ActionTypes.TABLE_DATA_ADJUST_IDS:     /////////////////////////
            let data = [...state.data];
            let newIds = action.payload.data.map(row => row.id);

            newIds.forEach(newId => {
                    let indexCur = data.findIndex(el => el.id == newId);
                    let indexNew = action.payload.data.findIndex(el => el.id == newId)
                    // console.log(data[indexCur], action.payload.data[indexNew]);
                    data[indexCur] = {...data[indexCur], ...action.payload.data[indexNew]}
            });

            for(let idObject of action.payload.ids){
                if(idObject.prevId) {
                    data.find(el => el.id == idObject.prevId).id = idObject.id;
                }
            }

            return { ...state, data }

        case ActionTypes.TABLE_DATA_CHANGED: return { ...state, changed: action.payload.data }; /////////////////
        case ActionTypes.TABLE_CURRENT_DATE_CHANGING: return { ... state, date: action.payload.data }; /////////////
        
        default:  { 
            return  state;
        }
    }
}