import React from "react";
import { Route, Link } from "react-router-dom";


class BKRoute extends React.Component {

    render(){
        return (
            <Route path={this.props.to} exact={this.props.exact} children={(routeProps) => {
                let active = `header__menu-item ${routeProps.match ? 'header__menu-item_active' : ''}`;
                if(this.props.transform) {
                    active = routeProps.match ? this.props.transform(active) : active;
                }
                return <div className={active}>
                    <Link to={this.props.to}>{this.props.content}</Link>
                </div> 
            }}/>
        );
    }
}

export default BKRoute;