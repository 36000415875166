export default class Timer {

    constructor(func = null, time = null){
        this._func = func;
        this._time = time;
        this._isActive = false;
        this._interval = null;
        this._sync = null;
    }

    set sync(value){
        this._sync = value;
    }
    
    set isActive(value){
        this._isActive = value;
    }

    get isActive(){
        return this._isActive;
    }

    set func(value){
        this._func = value;
    }

    set time(value){
        this._time = value
    }

    get time(){
        return this._time;
    }

    start(){    
        this._isActive = true;
        // this._interval = setTimeout(this._func, this._time);
        return this;
    }

    stop(){
        clearInterval(this._sync);
        // clearTimeout(this._interval);
        this._isActive = false;
        return this;
    }

    reset(){
        this.stop().start();
    }

}