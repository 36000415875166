import numfns from "../utils/numfns";
import { isValid, parse } from "date-fns";

class Validator {

    constructor(headers){
        this.headers = headers;
    }

    check(unsavedData){
        let data = unsavedData.slice();

        if(data.length == 0) return false;
        for (let index = 0; index < data.length; index++) {
            const row = data[index];
            const keys = Object.keys(row);

            if(!this.checkCells(row)) return false;
            if(!numfns.isNumber(row.id)){
                if(keys.length != this.headers.length){
                    return false;
                }
            } 
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];

                if(key == 'id') continue;

                if(row[key] === '') return false;
            }

        }

        return true;
    }

    checkCells(row){
        let valid = true;
        let rowKeys = Object.keys(row);
        // console.log(rowKeys);
        // rowKeys.forEach((key) => {
        //     let header = this.headers.find(header => header.name == key);
        //     console.log(key, header);
        // });

        for (let index = 0; index < rowKeys.length; index++) {
            const header = this.headers.find(header => header.name == rowKeys[index]);
            if(!header) continue;
            const value = row[rowKeys[index]];
            // console.log('1:', value, header);
            switch(header.type){
                case 'id': break;
                case 'int': 
                    valid = Number.isInteger(Number(value));
                    break;
                case 'double':
                    valid = numfns.isNumber(value);
                    break;
                case 'string': break;
                case 'date':
                    let date = /-/.test(value) ? isValid(new Date(value)) ? new Date(value) : false : parse(value, 'dd.MM.yyyy', new Date());
                    //console.log('DATA', isValid(date), date, value);
                    valid = isValid(date);
                    break;
                case 'boolean':
                    valid = !(value != "Приход" && value != "Расход");
                    break;
                default: return false;
            }
            if(!valid) { 
                //console.log('Invalid type:', header.name, header.type, row);
                return valid;
            } else continue;

        }
       
        return valid;
    }

    checkKeysExist(row){
        for (let index = 0; index < this.headers.length; index++) {
            const header = this.headers[index];
            let isExistKey = false;

            if(header.name in row) isExistKey = true;

            if(!isExistKey) {
                //console.log('Key doesnt exist:', header.name);
                return false;
            }
        }
        return true;
    }
}

export default Validator;