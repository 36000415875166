export default function errorAxiosHandler(callback, err) {

    console.log(err);
    let message;
    if(typeof callback != 'function' && typeof callback == 'object'){
        err = callback;
        callback = null;
    }

    if(!err.response) message = 'Отсутствует подключение к интернету';
    else message = 'Сервер временно недоступен';   
    
    if(this.showMessage) this.showMessage(message, 'error');

    if(callback) callback();
}