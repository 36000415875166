import { ActionTypes } from "../types";

export const logIn = (data) => ({
    type: ActionTypes.USER_INITIALIZE_SESSION,
    payload: {
        data
    }
});

export const logOut = () => ({
    type: ActionTypes.USER_DESTROY_SESSION
});