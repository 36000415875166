import { ActionTypes } from "../types";

export const pageLoadStatus = (status) => ({
    type: ActionTypes.PAGE_LOAD_STATUS,
    payload: {
        data: status
    }
});

export const pageTitleChange = (title) => ({
    type: ActionTypes.PAGE_TITLE_CHANGED,
    payload: {
        data: title
    }
});

export const pagePopupAdd = (data) => ({
    type: ActionTypes.PAGE_POPUP_ADD,
    payload: { data }
});

export const pagePopupRemove = (data) => ({
    type: ActionTypes.PAGE_POPUP_REMOVE,
    payload: { data }
});

export const pageMainMenuShow= () => ({
    type: ActionTypes.PAGE_MAINMAIN_SHOW,
});
export const pageMainMenuHide = () => ({
    type: ActionTypes.PAGE_MAINMAIN_HIDE,
});
export const pageMainMenuToggle= () => ({
    type: ActionTypes.PAGE_MAINMAIN_TOGGLE,
});

export const pageConfirmTrigger = (show, message = '') => ({
    type: ActionTypes.PAGE_CONFIRM_TRIGGER,
    payload: { show, message }
});

export const pageSearchTrigger = (show) => ({
    type: ActionTypes.PAGE_SEARCH_TRIGGER,
    payload: { show }
});
