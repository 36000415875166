import React from "react";

import { Link, withRouter, NavLink, Route } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import { pageTitleChange, pageMainMenuShow } from  "../../data/actions/AppActions";

import BankInfo from "./BankInfo/BankInfo";
import BKRoute from "../../routing/BKRoute";
import SituationalMenu from "./SituationalMenu";
import { ReactComponent as Logo } from "./assets/logo.svg";
// import logo from "./assets/logo.png"
import { ReactComponent as PaymentsIcon } from "./assets/spreadsheet-bills.svg";
import { ReactComponent as BillsIcon } from "./assets/spreadsheet-payments.svg";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
// import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
// import { ReactComponent as HelpIcon } from "./assets/help.svg";
// import { ReactComponent as LogoutIcon } from "./assets/logout.svg";
// import { ReactComponent as UserIcon } from "./assets/user.svg";
import "./Header.scss";


class Header extends React.Component {

    // shouldComponentUpdate(nextProps, nextState){
    //     console.log('Header: ', this.props, nextProps, this.props == nextProps, this.props.location.pathname, nextProps.location.pathname);
    //     // if(this.props.location.pathname != nextProps.location.pathname) { console.log('not equal - rerender') ; return true; }
    //     // else 
    //     return true;
    // }

    render() {
        // console.log('HEADER RENDER', this.props);
        return (
            <section className="header">
                <div className="header__status-panel">{this.props.title}</div>
                <SituationalMenu pathname={this.props.location.pathname}/>
                <div className="header__bg-left-wing"></div>
                <div className="header__front-left-wing">
                    <div className="header__left-menu">
                        <div className="header__logo">
                            <div className="header__logo-content" onClick={e => { this.props.pageMainMenuShow(); e.stopPropagation(); }}>
                                <Logo/>
                            </div>
                        </div>
                        <BankInfo/>
                    </div>
                </div>
                <div className="header__bg-right-wing"></div>
                <div className="header__front-right-wing">
                    <div className="header__right-menu">
                        <BKRoute to="/calendar" content={<CalendarIcon/>}/>
                        <BKRoute to ="/bills" content={<PaymentsIcon/>} transform={(className) => {
                            return this.props.changed ? `${className} header__menu-item_unsave` : className;
                        }}/>
                        <BKRoute to ="/payments" content={<BillsIcon/>} transform={(className) => {
                            return this.props.changed ? `${className} header__menu-item_unsave` : className;
                        }}/>
                        <BKRoute to="/search" content={<SearchIcon/>}/>
                    </div>
                </div>
            </section>
        );
    }

}

const mapStateToProps = (state) => ({
    changed: state.table.changed,
    title: state.global.title,
})

const mapDispatchToProps = {
    pageTitleChange, 
    pageMainMenuShow,
};


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header);