import uuid from "uuid/v1";

let headers = [
    { title: 'ID',              style: { width: "30px"},    name: 'id',             type: 'id',         editable: false},
    { title: 'Контрагент',      style: { width: "280px"},   name: 'counterparty',   type: 'string',     editable: false, context: "cell-sbc"},
    { title: 'Номер счета',     style: { width: "83px"},   name: 'bill',           type: 'string',     editable: false},
    { title: 'Дата',            style: { width: "139px"},    name: 'date',           type: 'date',       editable: false},
    { title: 'Приход',          style: { width: "115px"},   name: 'incoming',       type: 'double',     editable: false},
    { title: 'Расход',          style: { width: "115px"},   name: 'consumption',    type: 'double',     editable: false},
    { title: 'На оплату',       style: { width: "115px"},   name: 'payment',        type: 'double'},
    { title: 'Оплачено',        style: { width: "115px"},   name: 'paid',           type: 'double'},
    { title: 'Сальдо',          style: { width: "115px"},   name: 'balance',        type: 'double',     editable: false},
    { title: 'Конечный срок',   style: { width: "79px"},   name: 'end_date',       type: 'date',       editable: false},
    { title: 'Дата оплаты', style: { width: "120px"},   name: 'upd_date',       type: 'date',       editable: false}
]; 

headers = headers.map(header => ({...header, key: uuid()}));

export default headers;