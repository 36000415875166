import { ActionTypes } from "../types";
import axios from "axios";

export const tableChangeData = (data) => ({ 
    type: ActionTypes.TABLE_CHANGE_CURRENT_DATA,
    payload: { data }
});

export const tableDataAddRow = (data) => ({ 
    type: ActionTypes.TABLE_DATA_ADD_NEW_ROW,
    payload: { data }
});

export const tableDataAdjustIds = (data, ids) => ({ 
    type: ActionTypes.TABLE_DATA_ADJUST_IDS,
    payload: { data, ids }
});

export const tableDataDeleteRow = (data) => ({ 
    type: ActionTypes.TABLE_DATA_DELETE_ROW,
    payload: { data }
});

export const tableUnsavedDataDeleteRow = (data) => ({
    type: ActionTypes.TABLE_UNSAVED_DATA_DELETE_ROW,
    payload: { data }
});

export const tableChangeCurrentDate = (date) => ({ 
    type: ActionTypes.TABLE_CURRENT_DATE_CHANGING,
    payload: {
        data: date
    }
});

export const tableChangeUpdDate = (date) => ({
    type: ActionTypes.TABLE_CHANGE_UPD_DATE,
    payload: {
        data: date
    }
});

export const tableChangeUpdTableName = (name) => ({
    type: ActionTypes.TABLE_CHANGE_UPD_TABLE_NAME,
    payload: {
        data: name
    }
});

// TODO: RENAME
export const tableChangeStatus = (status) => ({
    type: ActionTypes.TABLE_DATA_CHANGED,
    payload: {
        data: status
    }
});

// TODO: RENAME
export const tableAddRow = (id, content) => ({ 
    type: ActionTypes.TABLE_UNSAVED_DATA_ADD_ROW,
    payload: { 
        id,
        content
     }
});


// TODO: DELETE
export const tableChangesRowId = (oldId, newId) => ({
    type: ActionTypes.TABLE_UNSAVED_DATA_CHANGES_ROW_ID,
    payload: {
        oldId,
        newId
    }
});

// TODO: RENAME
export const tableClearChanges = () => ({
    type: ActionTypes.TABLE_CLEAR_CHANGES_DATA
})

export const tableSaved = (data, path) => (dispatch) => {
    return axios.post(`/api/table${path}/update`, {
        data
    }).then(({data: response}) => {
        //console.log('__________', response);
        if(response.status) {
            dispatch(tableChangeStatus(false));
            return response;
        } else return response;
    });
};