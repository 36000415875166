export default {

    findTr(startElement){
        let tr = startElement ? startElement : {};
        while(tr.tagName != "TR" && tr.parentElement){
            tr = tr.parentElement;
        }

        return tr.tagName == "TR" ? tr : null;
    },
    getRowId(element){
        let idRow;
        let tr = this.findTr(element);
        if(tr && tr.hasAttribute('row')) idRow = tr.getAttribute('row');
        return idRow;
    }
}